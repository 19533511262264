/* 
 * @Author: 曹俊杰 
 * @Date: 2022-11-01 15:49:59
 * @Module: 查询小号开通
 */
 <template>
  <div class="searchConformed">
    <div class="mask" v-if="!user.name||!user.company"></div>
    <div class="box" v-if="!user.name">
      <el-result icon="warning" title="警告提示" subTitle="开通小号查询系统需进行个人实名认证">
        <template slot="extra">
          <el-button type="primary" size="medium" @click="toAccountAuth">前去进行认证</el-button>
        </template>
      </el-result>
    </div>
    <div class="box" v-else-if="!user.company">
      <el-result icon="warning" title="警告提示" subTitle="开通小号查询系统需进行企业实名认证">
        <template slot="extra">
          <el-button type="primary" size="medium" @click="toAccountCompanyAuth">前去进行认证</el-button>
        </template>
      </el-result>
    </div>
    <div class="new" v-else>
      <div class="tit">
        <span>24小时</span>
        <span style="color:#000">系统监控查询</span>
      </div>
      <div class="dsc">24-hour system monitoring query</div>
      <div class="btn pointer" @click="submit">立即开通</div>

    </div>
    <!-- <div class="box" v-else>
      <p class="des">欢迎使用</p>
      <p class="title">真好签小号查询系统</p>
      <el-form label-position="left" hide-required-asterisk :model="form" ref="form" @submit.native.prevent>
        <el-form-item prop="code" label="邀请码">
          <el-input type="text" v-model="form.code" auto-complete="off" placeholder="请输入邀请码" @keyup.enter.native="submit"></el-input>
        </el-form-item>
        <el-form-item style="width:100%;">
          <el-button type="primary" style="width:100%;" @click="submit">立即开通</el-button>
        </el-form-item>
      </el-form>
      <div class="btn">
        <el-button type="text" size="mini" v-popover:popover>有疑问？联系专属客服</el-button>
      </div>
    </div> -->

    <el-dialog title="邀请码填写" :visible.sync="dialogVisible" width="30%">
      <div class="dialog">
        <p class="dialog-title">
          <span>邀请码</span>
          <span v-popover:popover class="pointer">有疑问？联系专属客服</span>
        </p>
        <el-form label-position="left" size="medium" hide-required-asterisk :model="form" ref="form" @submit.native.prevent>
          <el-form-item prop="code" style="margin-top:6px;">
            <el-input type="text" v-model="form.code" auto-complete="off" placeholder="请输入邀请码" @keyup.enter.native="submit"></el-input>
          </el-form-item>
        </el-form>
        <div class="dialog-btns">
          <div class="btn1 pointer" @click="dialogVisible=false">取消</div>
          <div class="btn2 pointer" @click="submit">开通</div>
        </div>
      </div>
      <el-popover ref="popover" placement="right" width="200" trigger="click">
        <div style="width:176px;height:176px;" class="serviceCode "></div>
      </el-popover>

    </el-dialog>

    <inform />
  </div>
</template>
 <script>
import inform from "@/components/inform.vue";
import { mapState } from "vuex";
export default {
  components: {
    inform
  },
  data () {
    return {
      form: {
        code: ''
      },
      dialogVisible: false
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
  },
  mounted () { },
  methods: {
    submit () {
      this.$router.go(-1)
      // this.$refs.form.validate((valid) => {
      //   if (valid) {
      //     this.getSearchConformed()
      //   } else {
      //     return false;
      //   }
      // });


    },
    getSearchConformed () {
      this.$store.dispatch('user/getSearchConformed', {
        callback: () => {
          this.$router.go(-1)
        },
        inviteCode: this.form.code
      })
    },
    toAccountAuth () {
      this.$store.dispatch('user/toAccountAuth')
    },
    toAccountCompanyAuth () {
      this.$store.dispatch('user/toAccountCompanyAuth')
    },
  },
};
 </script>
 <style lang='scss' scoped>
.searchConformed {
  background-image: url(~@/static/image/searchConformed_bg_1.png);
  background-color: #fff;
  height: calc(100vh - 158px);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  .mask {
    width: 100vw;
    height: 100vh;
    background: rgba($color: #000000, $alpha: 0.6);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1001;
  }
  .box {
    background: #f66;
    box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    border: 1px solid #e3e3e3;
    width: 440px;
    background: #fff;
    padding: 30px;
    position: fixed;
    z-index: 1002;
    .des {
      font-size: 22px;
      font-weight: 500;
      color: #262626;
      line-height: 30px;
    }
    .title {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #0062ff;
      line-height: 22px;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .btn {
      display: flex;
      justify-content: flex-end;
    }
  }
  .new {
    .tit {
      font-family: "Source Han Sans CN";
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 60px;
      color: #0062ff;
      text-align: center;
    }
    .dsc {
      font-family: "Source Han Sans CN";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.541881px;
      text-transform: uppercase;
      color: #666666;
      margin-top: 10px;
      text-align: center;
    }
    .btn {
      width: 400px;
      line-height: 44px;
      background: #0062ff;
      mix-blend-mode: normal;
      border-radius: 4px;
      margin-top: 24px;
      color: #fff;
      text-align: center;
    }
  }
  &/deep/.el-dialog__body {
    padding-top: 0px;
  }
  .dialog {
    &-title {
      display: flex;
      justify-content: space-between;
      font-family: "Source Han Sans CN";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.103793px;
      color: #666666;
    }
    &-btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .btn1 {
        background: #ffffff;
        border: 1px solid #e3e3e3;
        border-radius: 4px;
        width: 80px;
        line-height: 32px;
        text-align: center;
      }
      .btn2 {
        background: #0062ff;
        border-radius: 4px;
        width: 80px;
        line-height: 32px;
        text-align: center;
        color: #fff;
        margin-left: 16px;
      }
    }
  }
}
.serviceCode {
  background-image: url(~@/static/service.jpg);
  background-size: 100%;
}
</style>